body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-VariableFont_wght"),
   url("./fonts/Montserrat/Montserrat-VariableFont_wght.ttf") format("truetype");
  
  }

@font-face {
  font-family: "Lato";
  src: local("Lato-Regular"),
    url('./fonts/Lato/Lato-Regular.ttf') format("truetype");
    
  }

@font-face {
  font-family: "FrancoisOne";
  src: local("FrancoisOne"),
    url('./fonts/FrancoisOne/FrancoisOne-Regular.ttf') format("truetype");
      
}
  
@font-face {
  font-family: "OpenSans";
  src: local("OpenSans"),
    url('./fonts/OpenSans/OpenSansVariable.ttf') format("truetype");
      
}

#spinner {
 color: #d91e53;
}

#spinner {
  color: #d91e53;
  left: 50%;
  top: 30%;
  z-index: 1000;
  position: absolute;
}